import {
  ChangeEvent,
  Dispatch,
  FC,
  JSX,
  SetStateAction
} from "react";
import { Form, FormInstance, Input } from "antd";
import { handlePhoneNumberChange } from "../../../../../utils/phoneNumberMask";

interface IPhoneInputProps {
  form: FormInstance;
  phoneNumberBlur: boolean;
  setPhoneNumberBlur: Dispatch<SetStateAction<boolean>>;
}

const PhoneInput: FC<IPhoneInputProps> = ({
  form,
  phoneNumberBlur,
  setPhoneNumberBlur
}): JSX.Element => {
  const handleValidateStatus: "error" | "" = form.getFieldValue("phoneNumber" as any)?.length > 0
      ? "" : "error";
  const handleHelp: null | string = form.getFieldValue("phoneNumber" as any) ? null : "Только цифры";
  
  const handleBlur = (): void => {
    setPhoneNumberBlur(true);
  };
  
  const handlePhoneNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const formattedNumber: string = handlePhoneNumberChange(value);
    form.setFieldValue("phoneNumber" as any, formattedNumber);
  };
  
  return (
    <Form.Item
      name="phoneNumber"
      className="mb-0 w-1/2"
      label="Телефон"
      validateStatus={phoneNumberBlur ? handleValidateStatus : ""}
      help={phoneNumberBlur ? handleHelp : null}
    >
      <Input
        id="phoneNumber"
        size="large"
        placeholder="+7 000 000 00 00"
        value={form.getFieldValue("phoneNumber" as any)}
        onChange={handlePhoneNumber}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};

export default PhoneInput;