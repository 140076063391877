import {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { Form, Modal, Tooltip } from "antd";
import css from "./UsersModal.module.css";
import { UserDataType } from "../../UsersList/UsersListTable/UsersListTable";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { useWatch } from "antd/es/form/Form";
import { handlePhoneNumberChange } from "../../../../utils/phoneNumberMask";
import { getKeycloakUsers } from "../../../../api/keycloak.api";
import {
  BlockerInfoType,
  CreatFormType,
  UserBlockInfoType,
  UserNameType
} from "./utils/userTypes";
import {
  KeycloakCreateUserType,
  AllUsersListingType,
  UserListingType,
  FilterTasksType
} from "app/types";
import debounce from "debounce";
import { AxiosResponse } from "axios";
import NameInput from "./UsersModalItems/NameInput";
import EmailInput from "./UsersModalItems/EmailInput";
import PhoneInput from "./UsersModalItems/PhoneInput";
import RolesSelect from "./UsersModalItems/RolesSelect";
import {
  fetchBlockDataUser,
  fetchCreateUser,
  fetchEditUser,
} from "./utils/usersAction";
import BlockInfo from "./UsersModalItems/BlockInfo";

interface ICreatUsersModalProps {
  isOpenModal: boolean;
  modalVariant: string;
  userData: UserDataType[];
  setUserData: Dispatch<SetStateAction<UserDataType[]>>;
  setIsOpenModal: Dispatch<SetStateAction<boolean>>;
  setUpdateListing: Dispatch<SetStateAction<boolean>>;
}

const { useForm } = Form;

const debouncedGetUsersListing: debounce.DebouncedFunction<(
  params: FilterTasksType,
  onSuccess: (value: AxiosResponse<AllUsersListingType>) => void,
  onError: any,
) => void> = debounce((params, onSuccess, onError): void => {
  getKeycloakUsers(params)
    .then(onSuccess)
    .catch(onError);
}, 500);

const UsersModal: FC<ICreatUsersModalProps> = ({
  isOpenModal,
  modalVariant,
  userData,
  setUserData,
  setIsOpenModal,
  setUpdateListing
}): JSX.Element => {
  const [form] = useForm();
  const values: CreatFormType = useWatch([], form);
  const {
    surname,
    firstName,
    secondName,
    email,
    phoneNumber,
    role
  } = values || {};
  
  const [usersKeycloakList, setUsersKeycloakList] = useState<UserListingType[]>([]);
  const [hasCheckedEmail, setCheckedMail] = useState<boolean>(false);
  const [validMail, setValidMail] = useState<boolean>(true);
  const [isFormHasValues, setFormHasValues] = useState<boolean>(false);
  const [phoneNumberBlur, setPhoneNumberBlur] = useState<boolean>(false);
  const [emailBlur, setEmailBlur] = useState<boolean>(false);
  const [isOpenPopover, setOpenPopover] = useState<boolean>(false);
  const [blockInfo, setBlockInfo] = useState<UserBlockInfoType[]>([]);
  const [blockerInfo, setBlockerInfo] = useState<BlockerInfoType | null>(null);
  
  const filteredList: UserListingType[] = usersKeycloakList.filter(
    ({ email }): boolean => email !== userData?.[0]?.email
  );
  const userStatus: string = userData?.[0]?.userStatus || "";
  const isEditVariant: boolean = modalVariant === "edit";
  const isBookedEmail: boolean = !!filteredList.length;
  const isDisableButtonOk: boolean = isFormHasValues || isBookedEmail || !hasCheckedEmail || !validMail;
  const isBlockedUser: boolean = userStatus === "blocked";
  
  const userName = (fullName: string | undefined): UserNameType | null => {
    if (!fullName) {
      return null;
    }

    const nameParts: string[] = fullName.split(" ");
    const surName: string = nameParts[0];
    const firstName: string = nameParts[1];
    const secondName: string = nameParts[2];
    
    return {
      surName: surName,
      firstName: firstName,
      secondName: secondName
    };
  };
  
  useEffect(() => {
    if (isEditVariant) {
      const { email, full_name, phone, userRoles } = userData?.[0] || {};
      const { surName, firstName, secondName } = userName(full_name) || {};
      const correctFormPhoneNumber: string = handlePhoneNumberChange(phone as string);
      
      form.setFieldsValue({
        surname: surName,
        firstName,
        secondName,
        email,
        phoneNumber: correctFormPhoneNumber,
        role: userRoles
      });
      
      if (isBlockedUser) {
        const userUuid: string = userData?.[0]?.key || "";
        
        fetchBlockDataUser(userUuid, setBlockInfo);
      }
    }
  }, [modalVariant, userData]);
  
  useEffect((): void => {
    if (values) {
      const requiredFields: CreatFormType = form.getFieldsValue([
        "surname",
        "firstName",
        "email",
        "role"
      ] as any);
      
      const hasNoValue: boolean = Object.values(requiredFields)
        .some((value: string | string[]): boolean => typeof value === "string" ? !value : !value?.length);
      
      setFormHasValues(hasNoValue);
    }
  }, [values, isBookedEmail]);
  
  useEffect(() => {
    if (isOpenModal && email) {
      setCheckedMail(false);
      
      const params: FilterTasksType = {
        search: email
      };

      debouncedGetUsersListing(
        params,
        (response: any): void => {
          setUsersKeycloakList(response.data.results as UserListingType[]);
          setCheckedMail(true);
        },
        (err: any): void => {
          console.error("Get users error:", err);
          setCheckedMail(true);
        }
      );

      return (): void => {
        debouncedGetUsersListing.clear();
      };
    }

    return (): void => {
      setUsersKeycloakList([]);
    };
  }, [isOpenModal, email]);
  
  const handleCancel = (): void => {
    setOpenPopover(false);
    
    setTimeout(() => {
      setUserData([]);
      setBlockerInfo(null);
      setIsOpenModal(false);
      setFormHasValues(false);
      setValidMail(true);
      setEmailBlur(false);
      setPhoneNumberBlur(false);
      form.resetFields();
    }, 0);
  };
  
  const handleOk = (): void => {
    const paramsData: KeycloakCreateUserType = {
      username: [surname, firstName, secondName].join(" "),
      email,
      firstName,
      lastName: surname,
      emailVerified: true,
      enabled: true,
      attributes: {
        status: isEditVariant ? userStatus : "active",
        first_name: firstName,
        last_name: surname,
        second_name: secondName,
        ...(phoneNumber ? { phone: phoneNumber.replace(/[\s-]/g, "") } : {})
      },
      roles: role,
    };
    
    handleCancel();

    if (isEditVariant) {
      const userUuid: string = userData?.[0]?.key || "";

      fetchEditUser(userUuid, paramsData, setUpdateListing, userData);
    } else {
      const notificationUserData: { key: string, full_name: string }[] = [
        {
          key: "1",
          full_name: [surname, firstName, secondName].join(" ")
        }
      ];

      fetchCreateUser(paramsData, setUpdateListing, notificationUserData);
    }
  };
  
  const modalTitle: JSX.Element = (
    <div className={css.modalTitle}>
      {isEditVariant ? "Редактирование пользователя" : "Создание пользователя"}
    </div>
  );
  
  const contentTooltip = (text: string): JSX.Element => (
    <div className={css.tooltip}>
      {text}
    </div>
  );
  
  const buttonOk: JSX.Element = (
    <ButtonCustom
      key="ok"
      size="large"
      text="Сохранить"
      type="primary"
      onClick={handleOk}
      disabled={isDisableButtonOk}
    />
  );
  
  const modalFooter: JSX.Element = (
    <div key="modalBotton" className="flex justify-end">
      <ButtonCustom
        className="mr-2"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={handleCancel}
      />
      {isFormHasValues ? (
        <Tooltip
          placement="topRight"
          title={contentTooltip("Заполните все обязательные поля помеченные звездочкой (*)")}
          trigger="hover"
          arrow={{ pointAtCenter: true }}
        >
          <div>
            {buttonOk}
          </div>
        </Tooltip>
      ) : (
        buttonOk
      )}
    </div>
  );
  
  return (
    <Modal
      width={572}
      title={modalTitle}
      open={isOpenModal}
      onCancel={handleCancel}
      footer={modalFooter}
      centered
    >
      <Form
        form={form}
        className="m-0"
        layout="vertical"
      >
        {(isEditVariant && isBlockedUser) && (
          <BlockInfo
            blockerInfo={blockerInfo}
            setBlockerInfo={setBlockerInfo}
            blockInfo={blockInfo}
            isOpenPopover={isOpenPopover}
            setOpenPopover={setOpenPopover}
            setUpdateListing={setUpdateListing}
            handleCancel={handleCancel}
            userData={userData}
          />
        )}
        {["surname", "firstName", "secondName"].map((variant: string) => (
          <NameInput
            key={variant}
            form={form}
            variant={variant}
            required={variant !== "secondName"}
          />
        ))}
        <div className="mb-5 flex justify-between gap-3">
          <EmailInput
            form={form}
            isBookedEmail={isBookedEmail}
            emailBlur={emailBlur}
            validMail={validMail}
            setValidMail={setValidMail}
            setEmailBlur={setEmailBlur}
          />
          <PhoneInput
            form={form}
            phoneNumberBlur={phoneNumberBlur}
            setPhoneNumberBlur={setPhoneNumberBlur}
          />
        </div>
        <RolesSelect />
      </Form>
    </Modal>
  );
};

export default UsersModal;