import { FC, JSX } from "react";
import { Form, Select, Tag } from "antd";
import { rolesList } from "../utils/roleList";
import { SubstitutesType } from "app/types";
import css from "../UsersModal.module.css";

interface IRolesSelectProps {
}

const RolesSelect: FC<IRolesSelectProps> = (): JSX.Element => {
  const filteredRoles: SubstitutesType[] = rolesList.filter(({ key }): boolean => key !== "tech-support-role");
  
  const responsibleTagRender = (props: any): JSX.Element => {
    const { value, closable, onClose } = props;
    const data: SubstitutesType | undefined = rolesList.find(({ key }): boolean => key === value);
    
    return (
      <Tag
        className={css.blockResponsibleTag}
        closable={closable}
        onClose={onClose}
      >
        <div>{data?.label}</div>
      </Tag>
    );
  };
  
  return (
    <Form.Item
      name="role"
      className="mb-5"
      label="Роль"
      required
    >
      <Select
        id="role"
        size="large"
        showSearch
        allowClear
        options={filteredRoles}
        mode="multiple"
        placeholder="Выберите роль"
        tagRender={responsibleTagRender}
        filterOption={(input: string, option: any) =>
          (option?.label ?? "")?.toLowerCase().includes(input.toLowerCase())
        }
      />
    </Form.Item>
  );
};

export default RolesSelect;