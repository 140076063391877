import { activeUsers, creatUser, editUser, getBlockDataUser } from "../../../../../api/keycloak.api";
import { usersNotification } from "../../../utils/Notifications/Notifications";
import { Dispatch, SetStateAction } from "react";
import { ParamsType } from "../../BlockingUsersModal/BlockingUsersModal";
import { UserDataType } from "../../../UsersList/UsersListTable/UsersListTable";
import { KeycloakCreateUserType } from "app/types";
import { fetchSelectedUser } from "../../../../../api/account.api";

const okResponse: number = 200;

export const fetchActiveUser = async (
  paramsData: ParamsType,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  blockUsers: UserDataType[],
): Promise<void> => {
  try {
    const response = await activeUsers(paramsData);
    
    if (response.status === okResponse) {
      setUpdate((prev: boolean) => !prev);
      usersNotification("usersActiveSuccess", blockUsers);
    }
  } catch (error) {
    usersNotification("usersActiveFailure");
    console.error("Block user error:", error);
  }
};

export const fetchCreateUser = async (
  paramsData: KeycloakCreateUserType,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  notificationUserData: { key: string, full_name: string }[],
): Promise<void> => {
  try {
    const response = await creatUser(paramsData);
    
    if (response.status === okResponse) {
      setUpdate((prev: boolean) => !prev);
      usersNotification("usersCreateSuccess", notificationUserData);
    }
  } catch (error) {
    usersNotification("usersCreateFailure");
    console.error("Create user error:", error);
  }
};

export const fetchEditUser = async (
  userUuid: string,
  paramsData: KeycloakCreateUserType,
  setUpdate: Dispatch<SetStateAction<boolean>>,
  userData: UserDataType[],
): Promise<void> => {
  try {
    const response = await editUser(userUuid, paramsData);
    
    if (response.status === okResponse) {
      setUpdate((prev: boolean) => !prev);
      usersNotification("usersEditSuccess", userData);
    }
  } catch (error) {
    usersNotification("usersEditFailure");
    console.error("Edit user error:", error);
  }
};

export const fetchBlockDataUser = async (
  userUuid: string,
  setBlockInfo: Dispatch<SetStateAction<any>>
): Promise<void> => {
  const params = {
    order_by: "-created_at",
    page_size: "1",
    user_actions_type: "blocked"
  };
  
  try {
    const response = await getBlockDataUser(userUuid, params);
    
    if (response.status === okResponse) {
      setBlockInfo(response?.data?.result);
    }
  } catch (error) {
    console.error("Edit user error:", error);
  }
};

export const getBlockerInfo = async (
  blockerUuid: string,
  setBlockerInfo: Dispatch<SetStateAction<any>>
): Promise<void> => {
  try{
    const response = await fetchSelectedUser(blockerUuid);
    setBlockerInfo(response?.data);
  } catch (err) {
    console.error("Get user info error:", err);
  }
};