export const handlePhoneNumberChange = (phoneNumber: string): string => {
  // Удаление всего кроме цифр
  const inputValue: string = phoneNumber?.replace(/\D/g, "");

  // Используем регулярное выражение для извлечения сегментов номера
  const matches: RegExpMatchArray | null = inputValue?.match(/^(7?)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
  if (!matches) {
    return "+7";
  }

  // Строим форматированный номер телефона в зависимости от количества доступных цифр
  let formattedPhone: string = inputValue?.length ? "+7" : "";

  if (matches[2]) {
    formattedPhone += ` ${matches[2]}`;
  }
  if (matches[3]) {
    formattedPhone += ` ${matches[3].slice(0, 3)}`;
  }
  if (matches[4]) {
    formattedPhone += `-${matches[4]}`;
  }
  if (matches[5]) {
    formattedPhone += `-${matches[5]}`;
  }

  return formattedPhone;
};

export const normalFormatPhoneNumber = (phoneNumber: string): string => {
  const cleaned: string = phoneNumber?.replace(/[\s-]/g, "");

  return cleaned?.replace(/^\+7/, "8");
};

export const phoneValidator = (_: any, value: string): Promise<void> => {
  if (!value || /^\+7 \d{3} \d{3}-\d{2}-\d{2}$/.test(value)) {
    // Разрешаем пустое значение или номер, содержащий только цифры, пробелы и тире
    return Promise.resolve();
  }

  return Promise.reject(new Error("Пожалуйста, введите правильный номер"));
};
