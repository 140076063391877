import { SubstitutesType } from "app/types";

export const rolesList: SubstitutesType[] = [
  {
    key: "admin-role",
    value: "admin-role",
    label: "Администратор",
  },
  {
    key: "accountant-role",
    value: "accountant-role",
    label: "Бухгалтер",
  },
  {
    key: "quality-control-role",
    value: "quality-control-role",
    label: "Контроль качества",
  },
  {
    key: "contact-center-role",
    value: "contact-center-role",
    label: "Сотрудник Контакт Центра",
  },
  {
    key: "supervisor-role",
    value: "supervisor-role",
    label: "Авторизованное лицо заказчика",
  },
  {
    key: "tech-support-role",
    value: "tech-support-role",
    label: "Техническая поддержка",
  },
];