import {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  Form,
  Input,
  Modal,
  Select,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import css from "./BlockingUsersModal.module.css";
import { UserDataType } from "../../UsersList/UsersListTable/UsersListTable";
import {
  UserListingType,
  UsersListingFilterType,
  AllUsersListingType,
  SearchResponsibleEmployeeType
} from "app/types";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search_icon.svg";
import { ReactComponent as WarningIcon } from "../../../../assets/icons/blue-warning.svg";
import ZeroSearch from "../../../ClientsList/ZeroSearch/ZeroSearch";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { blockUsers } from "../../../../api/keycloak.api";
import { usersNotification } from "../../utils/Notifications/Notifications";
import dayjs from "dayjs";
import qs from "qs";
import debounce from "debounce";
import { AxiosResponse } from "axios";
import { getUsersListing } from "../../../../api/account.api";

interface IBlockingUsersModalProps {
  isOpenModal: boolean;
  setIsOpenBlockModal: Dispatch<SetStateAction<boolean>>;
  setUpdateListing: Dispatch<SetStateAction<boolean>>;
  userData: UserDataType[];
  setSelectedRows: Dispatch<SetStateAction<UserDataType[]>>;
}

export type ParamsType = {
  blocked_users?: (string | undefined)[],
  block_reason?: string | null,
  substitutes?: string[] | null,
  active_users?: (string | undefined)[],
  users?: (string | undefined)[],
  status?: string,
}

const { TextArea } = Input;
const { useForm } = Form;

const debouncedGetUsersListing: debounce.DebouncedFunction<(
  params: string,
  onSuccess: (value: AxiosResponse<AllUsersListingType>) => void,
  onError: any,
) => void> = debounce((params, onSuccess, onError): void => {
  getUsersListing(params)
    .then(onSuccess)
    .catch(onError);
}, 500);

const correctRoles: string[] = ["accountant-role", "contact-center-role", "supervisor-role"];
const excludeRoles: string[] = ["admin-role", "tech-support-role", "quality-control-role"];

const BlockingUsersModal: FC<IBlockingUsersModalProps> = ({
  isOpenModal,
  setIsOpenBlockModal,
  setUpdateListing,
  userData,
  setSelectedRows
}): JSX.Element => {
  const [form] = useForm();
  const values = useWatch([], form);
  const { comment } = values || {};
  
  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string>("");
  const [
    employeesForChangeResponsibleOptions,
    setEmployeesForChangeResponsibleOptions
  ] = useState<SearchResponsibleEmployeeType[]>([]);
  const [responsibleUserList, setResponsibleUserList] = useState<UserListingType[]>([]);
  const [selectedResponsible, setSelectedResponsible] = useState<string[]>([]);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  
  const activeUsers: UserDataType[] = userData.filter(({ userStatus }): boolean => userStatus !== "blocked");
  
  const isOneUser: boolean = userData?.length === 1;
  const userRoles: string[] = userData?.flatMap(
    (user: UserDataType) => user?.userRoles?.filter((role: string) => !excludeRoles.includes(role)) || []
  ) || [];
  const hasCorrectRoles: boolean = userRoles.length > 0;
  
  useEffect(() => {
    const employeesForChangeResponsibleOptions: SearchResponsibleEmployeeType[] | undefined =
      responsibleUserList?.filter(({ id }): boolean => id !== userData?.[0]?.key)?.map(({
        id,
        attributes,
      }) => (
        {
          value: id,
          label: (
            <span>
              {attributes?.last_name?.toString()}
              {" "}
              {attributes?.first_name?.toString()}
              {" "}
              {attributes?.second_name?.toString()}
            </span>
          )
        }
      )
    );
    
    setEmployeesForChangeResponsibleOptions(employeesForChangeResponsibleOptions as SearchResponsibleEmployeeType[]);
  }, [responsibleUserList]);
  
  useEffect(() => {
    if (isOpenModal) {
      setIsUsersLoading(true);

      const params: UsersListingFilterType = {
        page_size: 100,
        roles: hasCorrectRoles ? userRoles : correctRoles,
        available_from: dayjs().format("YYYY-MM-DD"),
        available_to: dayjs().format("YYYY-MM-DD"),
        ...(searchEmployeeValue ? { name: searchEmployeeValue } : {}),
      };
      
      if (isOneUser && hasCorrectRoles) {
        const queryString: string = qs.stringify(params, { arrayFormat: "repeat" });

        debouncedGetUsersListing(queryString,
          (response: any): void => {
            setResponsibleUserList(response.data.results as UserListingType[]);
            setIsUsersLoading(false);
          },
          (err: any): void => {
            console.error("Get users error:", err);
            setIsUsersLoading(false);
          },
        );
      }
      
      return (): void => {
        debouncedGetUsersListing.clear();
      };
    }
    
    return setResponsibleUserList([]);
  }, [isOpenModal, searchEmployeeValue]);
  
  useEffect(() => {
    if (isOpenModal){
      setSelectedResponsible([]);
      setSearchEmployeeValue("");
      setEmployeesForChangeResponsibleOptions([]);
      form.resetFields();
    }
  }, [isOpenModal]);

  const modalTitle: JSX.Element = <div className={css.modalTitle}>Блокировка пользователей</div>;
  
  const handleOk = (): void => {
    const paramsData: ParamsType = {
      blocked_users: activeUsers.map(({ key }) => key),
      block_reason: comment || "",
      substitutes: selectedResponsible,
    };

    blockUsers(paramsData)
      .then((response) => {
        if (response.status === 200) {
          setSelectedRows([]);
          setUpdateListing((prev: boolean) => !prev);
          usersNotification("usersBlockSuccess", activeUsers);
        }
      })
      .catch((error) => {
        usersNotification("usersBlockFailure");
        console.error("Block user error:", error);
      })
      ?.finally(() => setIsOpenBlockModal(false));
  };
  
  const handleCancel = (): void => {
    setIsOpenBlockModal(false);
  };
  
  const modalFooter: JSX.Element = (
    <div key="modalBotton">
      <ButtonCustom
        className="mr-2"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={handleCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={handleOk}
      />
    </div>
  );

  const placeholderSelect = (text: string): JSX.Element => {
    return (
      <div className="flex justify-between items-center">
        <div>{text}</div>
        <SearchIcon className={css.searchIcon} />
      </div>
    );
  };

  return (
    <Modal
      width={572}
      title={modalTitle}
      open={isOpenModal}
      onCancel={handleCancel}
      footer={modalFooter}
      centered
    >
      <Form
        form={form}
        className="m-0"
        layout="vertical"
      >
        <div className={css.modalHeader}>Выбранные пользователи</div>
        <div className={css.userName}>
          {activeUsers?.map(({ key, full_name }) =>
            <div key={key}>{full_name}</div>
          )}
        </div>
        {isOneUser && hasCorrectRoles && (
          <>
            <div className={css.modalText}>
              Выберите пользователей, на которых будут переназначены активные
              задачи и клиенты, закрепленные за блокируемым сотрудником.
            </div>
            <div className="mb-5">
              <div className={css.textStyle}>Сотрудники для переназначения задач</div>
              <Form.Item name="responsible" className="mt-0 mb-0">
                <Select
                  id="responsible"
                  mode="multiple"
                  showSearch
                  placeholder={placeholderSelect("Найдите и выберите сотрудников по ФИО")}
                  size="large"
                  suffixIcon={null}
                  loading={isUsersLoading}
                  onChange={setSelectedResponsible}
                  filterOption={false}
                  onSearch={setSearchEmployeeValue}
                  searchValue={searchEmployeeValue}
                  onClear={() => setSearchEmployeeValue("")}
                  options={employeesForChangeResponsibleOptions}
                  notFoundContent={<ZeroSearch dataFilter />}
                  value={selectedResponsible}
                />
              </Form.Item>
            </div>
          </>
        )}
        {hasCorrectRoles && (
          <div className={css.infoBlock}>
            <WarningIcon className="flex-shrink-0 w-6 h-6" />
            <div className={css.textStyle}>
              {isOneUser
                ? "Вы можете пропустить выбор сотрудников для переназначения — задачи и " +
                "клиенты будут распределены автоматически."
                : "Задачи и клиенты, закрепленные за сотрудниками, выбранными на блокировку, " +
                "будут автоматически перераспределены между остальными активными сотрудниками."
              }
            </div>
          </div>
        )}
        <div className="mb-5">
          <div className={css.textStyle}>Причина блокировки</div>
          <Form.Item
            name="comment"
            noStyle
          >
            <TextArea
              id="comment"
              rows={3}
              maxLength={1200}
              className="text-base font-normal leading-5 text-gray-900"
              placeholder="Опишите причину блокировки"
            />
          </Form.Item>
          <div className="text-xs font-normal leading-100 counter-gray-color text-right">
            {comment?.length || 0}/1200
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default BlockingUsersModal;