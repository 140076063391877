import {
  ChangeEvent,
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect
} from "react";
import { Form, FormInstance, Input } from "antd";

interface IEmailInputProps {
  form: FormInstance;
  isBookedEmail: boolean;
  emailBlur: boolean;
  validMail: boolean;
  setValidMail: Dispatch<SetStateAction<boolean>>;
  setEmailBlur: Dispatch<SetStateAction<boolean>>;
}

const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const validRegex: RegExp = /[^A-Za-z0-9@._%?&-]/g;

const EmailInput: FC<IEmailInputProps> = ({
  form,
  isBookedEmail,
  emailBlur,
  validMail,
  setValidMail,
  setEmailBlur
}): JSX.Element => {
  useEffect(() => {
    if (form.getFieldValue("email" as any)) {
      const validEmail: string = form.getFieldValue("email" as any).replace(validRegex, "");
      
      setValidMail(emailRegex.test(validEmail));
    }
  }, [form]);
  
  const handleValidateEmailStatus = (): "error" | "" => {
    const emailValue = form.getFieldValue("email" as any);

    if (emailValue?.length > 0) {
      if (!validMail) {
        return "error";
      } else if (!isBookedEmail) {
        return "";
      }
    }

    return "error";
  };

  const handleEmailHelp = (): null | string => {
    const emailValue = form.getFieldValue("email" as any);

    if (emailValue?.length > 0) {
      if (!validMail) {
        return "Неправильный email";
      } else {
        return "Этот email уже используется";
      }
    }

    return null;
  };

  const shouldValidateEmailStatus = (): boolean => {
    return form.getFieldValue("email" as any) || (emailBlur && (isBookedEmail || !validMail));
  };

  const shouldShowEmailHelp = (): boolean => {
    return isBookedEmail || !validMail;
  };
  
  const handleBlur = (): void => {
    setEmailBlur(true);
  };
  
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const validEmail: string = value.replace(validRegex, "");
    
    setValidMail(emailRegex.test(validEmail));
    
    form.setFieldValue("email" as any, validEmail);
  };
  
  return (
    <Form.Item
      name="email"
      className="mb-0 w-1/2"
      label="Email"
      validateStatus={shouldValidateEmailStatus() ? handleValidateEmailStatus() : ""}
      help={shouldShowEmailHelp() ? handleEmailHelp() : null}
      required
    >
      <Input
        id="email"
        size="large"
        placeholder="Введите email"
        value={form.getFieldValue("email" as any)}
        onChange={handleEmailChange}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
};

export default EmailInput;