import { ChangeEvent, FC, JSX } from "react";
import { Form, FormInstance, Input } from "antd";

interface INameInputProps {
  form: FormInstance;
  variant: string;
  required?: boolean;
}

const holderText: Record<string, string> = {
  "surname": "Введите фамилию",
  "firstName": "Введите имя",
  "secondName": "Введите отчество",
};

const labelText: Record<string, string> = {
  "surname": "Фамилия",
  "firstName": "Имя",
  "secondName": "Отчество",
};

const NameInput: FC<INameInputProps> = ({
  form,
  variant,
  required = false
}): JSX.Element => {
  const handleNameChange = (e: ChangeEvent<HTMLInputElement>, variant: string): void => {
    const value: string = e.target.value;
    const validName: string = value.replace(/[^a-zA-Zа-яА-Я\s-]/g, "");
    
    form.setFieldValue(variant as any, validName);
  };
  
  return (
    <Form.Item
      name={variant as any}
      className="mb-5"
      label={labelText[variant]}
      required={required}
    >
      <Input
        id={variant}
        size="large"
        placeholder={holderText[variant]}
        value={form.getFieldValue(variant as any)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleNameChange(e, variant)}
      />
    </Form.Item>
  );
};

export default NameInput;