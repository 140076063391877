import {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import css from "../UsersModal.module.css";
import { Popover } from "antd";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as WarningIcon } from "../../../../../assets/icons/warning_orange_icon.svg";
import { ParamsType } from "../../BlockingUsersModal/BlockingUsersModal";
import { fetchActiveUser, getBlockerInfo } from "../utils/usersAction";
import { UserDataType } from "../../../UsersList/UsersListTable/UsersListTable";
import { BlockerInfoType, UserBlockInfoType } from "../utils/userTypes";
import dayjs from "dayjs";
import { performersRole } from "../../../../../utils/performersRole";
import LoadingCustom from "../../../../ui-kit/LoadingCustom/LoadingCustom";

interface IBlockInfoProps {
  blockerInfo: BlockerInfoType | null;
  setBlockerInfo: Dispatch<SetStateAction<BlockerInfoType | null>>;
  blockInfo: UserBlockInfoType[],
  isOpenPopover: boolean;
  setOpenPopover: Dispatch<SetStateAction<boolean>>;
  setUpdateListing: Dispatch<SetStateAction<boolean>>;
  handleCancel: () => void;
  userData: UserDataType[];
}

const BlockInfo: FC<IBlockInfoProps> = ({
  blockerInfo,
  setBlockerInfo,
  blockInfo,
  isOpenPopover,
  setOpenPopover,
  setUpdateListing,
  handleCancel,
  userData
}): JSX.Element => {
  const [causeText, setCauseText] = useState<boolean>(false);
  
  useEffect(() => {
    setBlockerInfo(null);
    
    if (blockInfo?.length) {
      const uuidBlockerInfo: string = blockInfo?.[0]?.initiator || "";
      
      getBlockerInfo(uuidBlockerInfo, setBlockerInfo);
    }
  }, [blockInfo]);
  
  const blockUsers: UserDataType[] = userData.filter(({ userStatus }): boolean => userStatus === "blocked");
  const reason: string = blockInfo?.[0]?.reason?.trim() || "";
  const hasBlockCause: boolean = reason.length > 0;
  const blockCauseText: string = hasBlockCause
    ? reason.charAt(0).toUpperCase() + reason.slice(1)
    : "";
  const blockingDate: string = blockInfo?.[0]?.created_at
    ? dayjs(blockInfo[0].created_at).format("DD.MM.YY HH:mm")
    : "";
  const userRoles: string = performersRole(blockerInfo?.roles || []);
  
  const userName = (): string => {
    const { last_name, first_name, second_name } = blockerInfo?.attributes || {};
    
    const lastName: string = last_name?.[0] || "";
    const firstName: string = first_name?.[0] || "";
    const secondName: string = second_name?.[0] || "";
    
    return `${lastName} ${firstName?.charAt(0)}. ${secondName?.charAt(0)}.`;
  };
  
  const handleActiveUser = (): void => {
    handleCancel();
    
    const paramsData: ParamsType = {
      users: blockUsers.map(({ key }) => key),
      status: "active",
    };
    
    fetchActiveUser(paramsData, setUpdateListing, blockUsers);
  };
  
  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };
  
  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };
  
  const contentPopover: JSX.Element = (
    <div>
      <div className="flex mb-2">
        <WarningIcon className="mr-2 w-[18px] flex-shrink-0" />
        <div className="w-[158px]">Вы уверены, что хотите отменить блокировку?</div>
      </div>
      <div className="flex justify-end gap-1">
        <ButtonCustom
          type="default"
          className={css.activeButton}
          text="Нет"
          onClick={handleClosePopover}
        />
        <ButtonCustom
          type="primary"
          text="Да"
          onClick={handleActiveUser}
        />
      </div>
    </div>
  );

  return (
    <div className={css.blockedInfo}>
      {blockerInfo ? (
        <div className="w-full flex items-center justify-between">
          <div>
            <div className={css.statusText}>Статус</div>
            <div className={css.text}>{`Заблокирован ${blockingDate}`}</div>
            <div className={`w-[90%] ${css.text}`}>{`Инициатор: ${userRoles}, ${userName()}`}</div>
          </div>
          <Popover
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
            content={contentPopover}
            open={isOpenPopover}
          >
            <ButtonCustom
              type="default"
              className={css.activeButton}
              onClick={handleOpenPopover}
              text="Активировать"
            />
          </Popover>
        </div>
      ) : (
        <LoadingCustom fontSize={20} className="m-auto"/>
      )}
      {hasBlockCause && (
        <div>
          <ButtonCustom
            type="link"
            className={`${causeText ? "mb-3" : ""} ${css.textButton}`}
            text={causeText ? "Скрыть причину" : "Показать причину"}
            onClick={() => setCauseText((prev: boolean) => !prev)}
          />
          {causeText && <div>{blockCauseText}</div>}
        </div>
      )}
    </div>
  );
};

export default BlockInfo;